import { z } from 'zod'

export const RequiredStringSchema = (name: string) => {
	return z
		.string({ required_error: `${name} is required` })
		.min(2, { message: `${name} is too short` })
		.max(100, { message: `${name}  is too long` })
}

export const OptionalCustomStringSchema = (name: string) => {
	return z
		.string({ required_error: `${name} is required` })
		.optional()
}

export const RequiredMessageSchema =
		z.string({ required_error: `Message is required` })
		.min(5, { message: `Message is too short` })
		.max(1000, { message: `Message is too long` })


export const UsernameSchema = z
	.string({ required_error: 'Username is required' })
	.email({ message: 'Email is invalid' })
	// users can type the username in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())
export const PhoneSchema = z
	.string({ required_error: 'Phone is required' })
	.regex(new RegExp('^(?:([0-9]{1})*[- .(]*([0-9]{3})[- .)]*[0-9]{3}[- .]*[0-9]{4})+$'), {
		message: 'Phone number not valid.',
	})
export const PasswordSchema = z
	.string({ required_error: 'Password is required' })
	.min(8, { message: 'Password is too short' })
	.max(100, { message: 'Password is too long' })
export const NameSchema = z
	.string({ required_error: 'Name is required' })
	.min(2, { message: 'Name is too short' })
	.max(40, { message: 'Name is too long' })

export const OptionalStringSchema = z.string().optional()

export const OptionalNumberSchema = z.number({ invalid_type_error: 'Must be a number.' }).optional()

export const SINWithHyphensSchema = z
	.string({ required_error: 'SIN is required' })
	.length(11, { message: 'SIN must be in the format XXX-XXX-XXX (11 characters)' })
	.regex(/^\d{3}-\d{3}-\d{3}$/, { message: 'SIN must be in the format XXX-XXX-XXX with 9 digits' });

export const SINSchema = z
	.string({ required_error: 'SIN is required' })
	.length(9, {message: 'Must be a 9 digit number'})
	.refine(data => /\d/.test(data), 'Only digits allowed.')

export const SSNSchema = z
	.string({ required_error: 'SSN is required' })
	.length(9, {message: 'Must be a 9 digit number'})
	.refine(data => /\d/.test(data), 'Only digits allowed.')

export const SINSchemaOptional = z
	.string()
	.length(9, { message: 'Must be a 9-digit number' })
	.refine(data => /^\d+$/.test(data), 'Only digits allowed.')
	.optional();

export const FirstNameSchema = z
	.string({ required_error: 'First name is required' })
	.min(2, { message: 'Name is too short' })
	.max(60, { message: 'Name is too long' })
	.refine(data => !/\d/.test(data), 'No digits allowed.')

export const LastNameSchema = z
	.string({ required_error: 'Last name is required' })
	.min(2, { message: 'Name is too short' })
	.max(60, { message: 'Name is too long' })
export const EmailSchema = z
	.string({ required_error: 'Email is required' })
	.email({ message: 'Email is invalid' })
	.min(3, { message: 'Email is too short' })
	.max(100, { message: 'Email is too long' })
	// users can type the email in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())

export const OptionalEmailSchema = z
	.string({ required_error: 'Email is required' })
	.email({ message: 'Email is invalid' })
	.min(3, { message: 'Email is too short' })
	.max(100, { message: 'Email is too long' })
	// users can type the email in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())
	.optional()

export const PasswordAndConfirmPasswordSchema = z
	.object({ password: PasswordSchema, confirmPassword: PasswordSchema })
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				path: ['confirmPassword'],
				code: 'custom',
				message: 'The passwords must match',
			})
		}
	})

export const BirthdaySchema = z.string().refine(
	value => {
		// Regular expression for matching the "YYYY-MM-DD" format
		const regex = /^\d{4}-\d{2}-\d{2}$/

		if (!regex.test(value)) {
			return false
		}

		const selectedDate = new Date(value);
		const today = new Date();
		today.setHours(0, 0, 0, 0); // Ignore time part to compare only dates
		return selectedDate < today;
	},
	{ message: 'Invalid birthday format. Ensure the date is in the past.' },
)

const MFACodeSchema = z.string({ required_error: 'Code is required' }).min(6).max(6)

export const ResetPasswordSchema = z
	.object({
		email: EmailSchema,
		password: PasswordSchema,
		confirmPassword: PasswordSchema,
		code: MFACodeSchema,
	})
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				path: ['confirmPassword'],
				code: 'custom',
				message: 'The passwords must match',
			})
		}
	})

export const ResetPasswordSchemaNoConfirmation = z
	.object({
		email: EmailSchema,
		password: PasswordSchema,
		code: MFACodeSchema,
	})
